import dynamic from 'next/dynamic'
import React, { createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { BasicSearchEventCard } from 'components/shared/cards'
import { GTM_EVENT_TYPES } from 'gtm'
import { getCoordinatesFromPlace } from 'helpers/legacy'
import { css } from 'styled-components'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { IconPinFilled } from '@vizeat/components/es6/assets/icons'
import { Box } from '@vizeat/components/es6/components/Box'
import { Text } from '@vizeat/components/es6/components/Text'

const RADIUS = 15

const MarkerWithPopup = dynamic(
  () => import('@vizeat/components/es6/components/Map').then((mod) => mod.MarkerWithPopup),
  { ssr: false },
)

const StandardMarker = dynamic(
  () => import('@vizeat/components/es6/components/Map').then((mod) => mod.StandardMarker),
  { ssr: false },
)

function getLatAndLngKey(event) {
  const position = getCoordinatesFromPlace(event.place)
  return `${position.lat}__${position.lng}`
}

const MopContext = createContext({})

const Marker = forwardRef(function Marker({ event, lat, lng, offsetTop, offsetLeft }, ref) {
  const { onMarkerClick, selectedEventId, handleSelectedEventIdToggle, highlightedEventsIds, shouldDisplayEventCard } =
    useContext(MopContext)
  const isMediumOrHigher = useMediaQuery('medium')
  const isHighlighted = highlightedEventsIds && highlightedEventsIds.includes(event.id)
  const isSelected = event.id === selectedEventId

  const { markerStyles, circleStyles } = useMemo(
    () => ({
      markerStyles: css`
        z-index: ${isHighlighted || isSelected ? 1 : undefined};
        &:hover {
          z-index: 2;
        }
      `,
      circleStyles:
        isHighlighted && !isSelected
          ? css`
              background: ${({ theme }) => theme.colors.white};
              border-color: ${({ theme }) => theme.colors.eatwithOrange};
              width: 24px;
              height: 24px;

              &:hover {
                background: ${({ theme }) => theme.colors.green};
                border-color: ${({ theme }) => theme.colors.white};
              }
            `
          : null,
    }),
    [isHighlighted, isSelected],
  )

  const toggle = useCallback(() => {
    handleSelectedEventIdToggle(event.id)
    onMarkerClick?.(event)
  }, [event, onMarkerClick, handleSelectedEventIdToggle])

  useEffect(() => {
    // unselect event on mobile if the event card is no longer visible in the carousel
    if (!isMediumOrHigher && isSelected && isHighlighted === false) {
      toggle()
    }
  }, [event, isHighlighted, isMediumOrHigher, isSelected, toggle])

  if (shouldDisplayEventCard) {
    return (
      <MarkerWithPopup
        isSelected={isSelected}
        showPopup={isSelected}
        markerRef={ref}
        latitude={lat}
        longitude={lng}
        css={markerStyles}
        togglePopup={toggle}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
      >
        <BasicSearchEventCard
          event={event}
          place={event.place}
          gtmType={GTM_EVENT_TYPES.SEARCH}
          hideNextAvailableDate
        />
      </MarkerWithPopup>
    )
  }

  return (
    <StandardMarker
      markerRef={ref}
      latitude={lat}
      longitude={lng}
      css={markerStyles}
      offsetTop={offsetTop}
      offsetLeft={offsetLeft}
      circleStyles={circleStyles}
      isSelected={isSelected}
      onClick={toggle}
    />
  )
})

Marker.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    place: PropTypes.shape({}),
  }).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  offsetTop: PropTypes.number,
  offsetLeft: PropTypes.number,
}

Marker.defaultProps = {
  offsetTop: undefined,
  offsetLeft: undefined,
}

function GroupedMarkers({ lat, lng, events }) {
  const angle = useRef(0)

  return (
    <>
      {events.map((event) => {
        const step = (2 * Math.PI) / events.length
        angle.current += step
        const left = Math.round(RADIUS * Math.cos(angle.current))
        const top = Math.round(RADIUS * Math.sin(angle.current))

        return <Marker key={event.id} event={event} lat={lat} lng={lng} offsetTop={top} offsetLeft={left} />
      })}
    </>
  )
}

GroupedMarkers.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}

export function MapEvents({ events, placeDetails, shouldDisplayEventCard, onMarkerClick, highlightedEventsIds }) {
  const groupedEventsByLatAndLngEntries = useMemo(() => {
    if (!events.size) return []
    const groupedEvents = events.reduce((acc, event) => {
      const latAndLng = getLatAndLngKey(event)
      return {
        ...acc,
        [latAndLng]: (acc[latAndLng] || []).concat(event),
      }
    }, {})

    return Object.entries(groupedEvents)
  }, [events])

  const [selectedEventId, setSelectedEventId] = useState(null)
  const handleSelectedEventIdToggle = useCallback((id) => {
    setSelectedEventId((currentId) => (id === currentId ? null : id))
  }, [])

  const contextValue = useMemo(
    () => ({
      highlightedEventsIds,
      onMarkerClick,
      selectedEventId,
      shouldDisplayEventCard,
      handleSelectedEventIdToggle,
    }),
    [highlightedEventsIds, onMarkerClick, selectedEventId, shouldDisplayEventCard, handleSelectedEventIdToggle],
  )

  const PlaceMarker = placeDetails ? (
    <StandardMarker
      Pin={
        <Box position='relative'>
          <IconPinFilled color='eatwithOrange' fontSize='36px' />
          <Text
            position='absolute'
            fontWeight='bolder'
            mb='0'
            left='50%'
            p='4px'
            bg='rgba(255, 255, 255, .5)'
            css={`
              white-space: nowrap;
              transform: translateX(-50%);
            `}
          >
            {placeDetails.locality}
          </Text>
        </Box>
      }
      latitude={placeDetails.lat}
      longitude={placeDetails.lng}
    />
  ) : null

  if (!events.size) return PlaceMarker

  return (
    <MopContext.Provider value={contextValue}>
      {groupedEventsByLatAndLngEntries.map(([latAndLng, events]) => {
        const [lat, lng] = latAndLng.split('__')
        const firstEvent = events[0]

        if (events.length > 1) {
          return <GroupedMarkers key={firstEvent.id} lat={Number(lat)} lng={Number(lng)} events={events} />
        }

        return <Marker key={firstEvent.id} event={firstEvent} lat={Number(lat)} lng={Number(lng)} />
      })}

      {PlaceMarker}
    </MopContext.Provider>
  )
}

MapEvents.propTypes = {
  events: PropTypes.immutable.list.isRequired,
  shouldDisplayEventCard: PropTypes.bool,
  onMarkerClick: PropTypes.func,
  highlightedEventsIds: PropTypes.immutable.list,
  placeDetails: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    locality: PropTypes.string,
  }),
}

MapEvents.defaultProps = {
  shouldDisplayEventCard: true,
  onMarkerClick: undefined,
  highlightedEventsIds: undefined,
  placeDetails: undefined,
}
