// libs
import React from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
// components
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Container } from '@vizeat/components/es6/components/Container'
import { NextImage } from 'components/shared/NextImage'
import { Text } from '@vizeat/components/es6/components/Text'

import DescendingDashedCurve from '../../../../../public/assets/images/dashed-curve-desc.svg'
import AscendingDashedCurve from '../../../../../public/assets/images/dashed-curve-asc.svg'

const StyledWrapper = styled(Box)`
  background-image: url(${DescendingDashedCurve});
  background-position: top right;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  ${(props) => props.theme.media.tablet`
    background-size: 60px 60px;
  `}
  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 100%;
    background-image: url(${AscendingDashedCurve});
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    ${(props) => props.theme.media.tablet`
      width: 60px;
  `}
  }
`

export function CobrandedBanner() {
  const { t } = useTranslation()
  const matches = useMediaQuery('tablet')

  return (
    <Container position='relative' my='32px'>
      <StyledWrapper bg='superPaleOrange' borderRadius='8px' maxWidth={{ tablet: '70%' }} mx='auto'>
        <Grid
          templateColumns={{ tablet: '80px auto', default: '60px auto' }}
          gap={{ tablet: '24px', default: '12px' }}
          alignItems='center'
          py={{ tablet: '24px', default: '12px' }}
          pl={{ tablet: '32px', default: '16px' }}
          pr={{ tablet: '84px', default: '28px' }}
        >
          <NextImage
            src='/assets/images/icon-eatwith-orange.svg'
            alt='eatwith logo'
            width={matches ? 80 : 60}
            height={matches ? 80 : 60}
          />
          <Grid gap='8px'>
            <Text
              as='span'
              type='attention'
              size={matches ? 'l' : 'xs'}
              style={{ fontSize: matches ? '18px' : '12px' }}
            >
              {t('Search::CobrandedBanner::Welcome to Eatwith, the largest social dining platform!')}
            </Text>
            <Text as='span' size={matches ? 'l' : 'xs'}>
              {t(
                'Search::CobrandedBanner::Dinners, food tours, cooking classes, wine tastings: choose your activity and your local host and get ready for an authentic experience!',
              )}
            </Text>
          </Grid>
        </Grid>
      </StyledWrapper>
    </Container>
  )
}
