// libs
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { Trans, useTranslation } from 'next-i18next'
// components
import { Button } from '@vizeat/components/es6/components/Button'
import { Box } from '@vizeat/components/es6/components/Box'
import { ButtonLink } from 'components/shared/ButtonLink'
// helpers
import { getLocality, getCountry } from 'helpers/places'
import { gtmSearchNoResults } from 'gtm'
import { useRouter } from 'next/router'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { isMapSearch } from 'helpers/search'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Text } from '@vizeat/components/es6/components/Text'

export function NoResults({ place, resetFilters }) {
  const { t } = useTranslation()
  const localityOrCountry = getLocality(place) || getCountry(place)
  const {
    query: { nelat, nelng, swlat, swlng, q },
  } = useRouter()

  useEffect(() => {
    if (!isMapSearch({ nelat, nelng, swlat, swlng })) {
      gtmSearchNoResults(localityOrCountry || q || 'no search query provided')
    }
  }, [localityOrCountry, nelat, nelng, q, swlat, swlng])

  const notFoundText = localityOrCountry
    ? t('Search::We could not find any host in {{localityOrCountry}}', { localityOrCountry })
    : t('Search::We could not find any host')

  return (
    <>
      <Box pt='24px' pl='16px' mr='16px'>
        <Heading as='h1' type='large' color='inherit'>
          {notFoundText}
        </Heading>

        <Text>
          {t(
            "Search::Make sure you didn't set too many filters, or try to zoom out on the map to see close-by experiences",
          )}
        </Text>

        {resetFilters && (
          <Button gray onClick={resetFilters}>
            {t('Search::Reset your filters')}
          </Button>
        )}
      </Box>

      {localityOrCountry && (
        <Flex
          bg='superPaleOrange'
          m='16px'
          p='16px'
          textAlign='left'
          fontSize='14px'
          borderRadius='lg'
          gap={{ default: '12px', large: '24px' }}
          alignItems='center'
          flexDirection={{ default: 'column', large: 'row' }}
        >
          <Box>
            <Heading as='h2' fontSize='16px' m='0'>
              {t('Search::Be the first host in {{localityOrCountry}}', { localityOrCountry })}
            </Heading>

            <Trans
              i18nKey={__(
                'Search::Looks like no one is hosting in {{localityOrCountry}}... <em>yet!</em>, Be the first host here and welcome people from around the world to your food experience',
              )}
              values={{ localityOrCountry }}
              components={{ em: <em /> }}
            />
          </Box>

          <ButtonLink
            href='/landings/become-host/become-a-host-on-eatwith'
            css={`
              min-width: 200px;
              white-space: initial;
            `}
          >
            {t('Search::Become a host in {{localityOrCountry}}', { localityOrCountry })}
          </ButtonLink>
        </Flex>
      )}
    </>
  )
}

NoResults.propTypes = {
  place: PropTypes.immutable.record,
  resetFilters: PropTypes.func,
}

NoResults.defaultProps = {
  resetFilters: undefined,
  place: undefined,
}
