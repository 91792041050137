// libraries
import React from 'react'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
// components
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'
import { Box } from '@vizeat/components/es6/components/Box'
import { SearchListView, SearchMobileListView } from 'components/pages/search/SearchListView'
// helpers
import { initApplication } from 'helpers/SSR'
import { buildSearchQueryKey, getSearchParamsFromRouterQuery, viewConstants } from 'helpers/search'
import { cacheRules } from 'helpers/cache'
import { COOKIE_KEYS } from 'helpers/cookies'
import { SearchForm } from 'components/pages/search'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { fetchSearch } from 'api/search'
import { SearchMapView } from 'components/pages/search/SearchMapView'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { getPlaylist } from 'api/playlist'
import { SmartLink } from 'components/shared/SmartLink'
import { NextImage } from 'components/shared/NextImage'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { MealType } from 'components/pages/search/SearchFilters/MealType'

export const getServerSideProps = async ({ locale, query, res, req }) => {
  const cookieCurrency = req.cookies[COOKIE_KEYS.EW_CURRENCY]
  initApplication({ currency: query.c || cookieCurrency, locale })

  res.setHeader('Cache-Control', cacheRules.search)

  const queryClient = new QueryClient()

  const playlist = query.playlist
    ? await queryClient.fetchQuery(['playlist', query.playlist], () => getPlaylist(query.playlist))
    : undefined

  /*
      Skip ssr prefetch if :
      > query.q is not provided, instead, the query will be fetched on the client using the geolocation
        to display the "events near me"
      > cookie currency is empty since it's set on the client
    */
  if (query.q && cookieCurrency) {
    const Promises = [
      getSearchParamsFromRouterQuery(query, { includeFilters: false }), // ignore the filters in order to preload the full list of facets
      getSearchParamsFromRouterQuery(query),
    ].map(({ playlist: playlistId, ...searchPayload }) => {
      const payload = {
        ...searchPayload,
        ...playlist?.search,
      }

      return queryClient.prefetchInfiniteQuery(buildSearchQueryKey(payload, cookieCurrency), () =>
        fetchSearch(payload, { allowPublicBookings: true }),
      )
    })

    await Promise.all(Promises)
  }

  return {
    props: {
      // https://github.com/TanStack/query/issues/1458
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

export default function SearchPage() {
  const { query } = useRouter()
  // set to true for ssr to allow immediate breakpoints detection through css media queries
  // all media-based conditional rendering will not work with React 18 because the pre-rendered html
  // must be identical to the hydrated one.
  const isMediumOrHigher = useMediaQuery('medium', true)

  return (
    <DefaultLayout bg='white'>
      <Box position={{ default: 'fixed', tablet: 'sticky' }} top='0' right='0' left='0' zIndex={2} bg='white'>
        {isMediumOrHigher && (
          <Box display={{ default: 'none', tablet: 'block' }}>
            <Header showSmallLogo hideExperiences />
          </Box>
        )}
        <Flex
          bg='white'
          borderTop='sm'
          borderTopColor='lightGray'
          justifyContent='center'
          alignItems='center'
          padding={{ default: '12px', tablet: '18px 64px' }}
          direction='column'
        >
          <Flex gap='8px' width='100%'>
            <Box
              as={SmartLink}
              href='/'
              flexShrink={0}
              display={{
                medium: 'none',
              }}
              css={`
                > span {
                  display: block !important;
                }
              `}
            >
              <NextImage src='/assets/images/icon-eatwith-orange.svg' alt='Eatwith Logo' width={53} height={53} />
            </Box>
            <SearchForm />
          </Flex>

          <MealType />
        </Flex>
      </Box>

      {isMediumOrHigher && (
        <Box display={{ default: 'none', medium: 'block' }}>
          {query.view === viewConstants.VIEW_MAP ? <SearchMapView /> : <SearchListView />}
          <Footer />
        </Box>
      )}

      {!isMediumOrHigher && (
        <Box display={{ default: 'block', medium: 'none' }}>
          <SearchMobileListView />
        </Box>
      )}
    </DefaultLayout>
  )
}
