import React, { useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { Dropdown } from '@vizeat/components/es6/components/Dropdown'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { IconChevronDown } from '@vizeat/components/es6/assets/icons'
import { useRouter } from 'next/router'
import { useUpdateRouterQuery } from 'hooks/router'
import PropTypes from 'helpers/proptypes'

function stopPropagation(e) {
  e.stopPropagation()
}

export function SortBy({ onClick }) {
  const { t } = useTranslation()
  const updateRouterQuery = useUpdateRouterQuery()
  const { sort_by: sortBy } = useRouter().query

  const sortingOptions = useMemo(
    () => ({
      relevance: t('Search::sort::relevance'),
      price_asc: t('Search::sort::price_asc'),
      price_desc: t('Search::sort::price_desc'),
      popularity: t('Search::sort::ratings'),
    }),
    [t],
  )

  const sortingOptionsEntries = useMemo(() => Object.entries(sortingOptions), [sortingOptions])

  function updateSortByQueryString(value) {
    onClick?.(value)
    updateRouterQuery({ sort_by: value })
  }

  return (
    <Dropdown
      id='search-sort-by'
      shouldFocus={false}
      shouldStopPropagation
      position='left'
      parentStyles='margin-left: auto;'
      dropdownStyles='bottom: -12px;'
      Trigger={
        <Flex
          forwardedAs='span'
          alignSelf='flex-end'
          alignItems='center'
          gap='8px'
          fontWeight='bolder'
          color='eatwithOrange'
          onTouchStart={stopPropagation}
          css={`
            cursor: pointer;
          `}
        >
          {sortingOptions[sortBy] || t('Search::sort::Sort by')} <IconChevronDown />
        </Flex>
      }
    >
      {({ handleDropdownHide }) => (
        <Flex
          forwardedAs='ul'
          flexDirection='column'
          textAlign='right'
          gap='8px'
          width='200px'
          p='24px 18px'
          fontSize='14px'
          margin='0'
          fontWeight='bolder'
          onClick={stopPropagation}
          onTouchStart={stopPropagation}
          css={`
            list-style: none;
          `}
        >
          {sortingOptionsEntries.map(([value, label]) => (
            <li
              key={value}
              role='button'
              onClick={() => {
                handleDropdownHide()
                updateSortByQueryString(value)
              }}
              css={`
                cursor: pointer;
                &:hover {
                  color: ${({ theme }) => theme.colors.eatwithOrange};
                }
              `}
            >
              {label}
            </li>
          ))}
        </Flex>
      )}
    </Dropdown>
  )
}

SortBy.propTypes = {
  onClick: PropTypes.func,
}

SortBy.defaultProps = {
  onClick: undefined,
}
