import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box } from '@vizeat/components/es6/components/Box'
import { getSearchParamsFromRouterQuery, viewConstants } from 'helpers/search'
import { useUpdateRouterQuery } from 'hooks/router'
import { getBounds } from 'helpers/places'
import { MapEvents } from './MapEvents'
import dynamic from 'next/dynamic'
import PropTypes from 'helpers/proptypes'
import { debounce } from 'lodash'
import { IconResize } from '@vizeat/components/es6/assets/icons'
import { RoundedIconWrapper } from 'components/shared/RoundedIconWrapper'
import styled, { css } from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useSearchQueryWithFallback } from 'hooks/search/useSearchQuery'
import { use100dvh } from '@vizeat/components/es6/hooks'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { List } from 'immutable'
import { urlFactory } from 'helpers/url'

const Map = dynamic(() => import('@vizeat/components/es6/components/Map').then((mod) => mod.Map), { ssr: false })

export const SPACE = 162
export const SPACE_WITH_FILTERS = SPACE + 50
export const MOBILE_SPACE = 85

const expandedFullMapToggleStyles = css`
  max-width: 100%;
  color: ${({ theme }) => theme.colors.eatwithOrange};
  border-color: ${({ theme }) => theme.colors.eatwithOrange};
  span {
    opacity: 1;
  }
`

const StyledViewFullMapToggle = styled(RoundedIconWrapper)`
  display: none;
  ${({ theme }) => theme.media.medium`
    display: flex
  `}

  cursor: pointer;
  justify-content: flex-start;
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 8px;
  padding-left: 4px;
  border: ${({ theme }) => `${theme.borders.sm} ${theme.colors.mediumGray}`};
  color: ${({ theme }) => theme.colors.mediumGray};
  width: auto;
  max-width: 36px;
  transition: max-width 0.2s ease;

  span {
    padding: 0 8px 0 4px;
    opacity: 0;
    white-space: nowrap;
    transition: opacity 0.2s ease;
  }

  &:hover {
    ${expandedFullMapToggleStyles}
  }

  ${({ isFullScreenMode }) => (isFullScreenMode ? expandedFullMapToggleStyles : null)}
`

export function SearchMapView({
  onMarkerClick,
  shouldDisplayEventCard,
  onViewportChange,
  highlightedEventsIds,
  paddings,
}) {
  const { query } = useRouter()
  const updateRouterQuery = useUpdateRouterQuery()
  const { t } = useTranslation()
  const isFullScreenMode = query.view === viewConstants.VIEW_MAP
  const viewportHeight = use100dvh()
  const hasMapQs = !!(query.nelat && query.nelng && query.swlng && query.swlat)
  const highlightedEventsIdsList = useMemo(
    () => (highlightedEventsIds ? List().concat(highlightedEventsIds) : undefined),
    [highlightedEventsIds],
  )

  const { selectData, combinedMapBounds, placeDetails } = useSearchQueryWithFallback(
    getSearchParamsFromRouterQuery(query),
    {
      allowPublicBookings: true,
      keepPreviousData: true,
    },
  )

  const { events, place, viewport, facets } = selectData((data) => data)
  const hasMealTypes = useMemo(() => {
    const mealTypes = facets.get('foodType')?.toJS()
    if (!mealTypes) return false
    return Object.values(mealTypes).sort().pop() > 0
  }, [facets])

  const isMediumOrHigher = useMediaQuery('medium')
  const mapPaddings = useMemo(() => {
    if (isMediumOrHigher) {
      return { top: 20, bottom: 20, left: 50, right: 50, ...paddings }
    }

    return { top: 100, bottom: 370, left: 40, right: 40, ...paddings }
  }, [isMediumOrHigher, paddings])

  const offsetTop = hasMealTypes ? SPACE_WITH_FILTERS : SPACE

  // ensure scroll to top when switching to the full map view
  useEffect(() => {
    if (isFullScreenMode) {
      window.scrollTo(0, 0)
    }
  }, [isFullScreenMode])

  // width and height are required as a number by WebMercatorViewport constructor
  // in this case we need to track the parent's width and height
  const [mapSize, setMapSize] = useState({
    width: '100%',
    height: '100%',
  })

  const parentRef = useRef(null)
  const handleParentRef = useCallback((node) => {
    if (!node) return
    parentRef.current = node
    setMapSize({
      width: node.offsetWidth,
      height: node.offsetHeight,
    })
  }, [])

  useEffect(() => {
    function updateMapSize() {
      setMapSize({
        width: parentRef.current?.offsetWidth || 0,
        height: parentRef.current?.offsetHeight || 0,
      })
    }

    updateMapSize()
    window.addEventListener('resize', updateMapSize)

    return () => {
      window.removeEventListener('resize', updateMapSize)
    }
  }, [hasMealTypes, viewportHeight])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleViewportChange = useCallback(
    debounce((_, bounds) => {
      const mapBounds = {
        nelat: bounds?.getNorthEast().lat,
        nelng: bounds?.getNorthEast().lng,
        swlat: bounds?.getSouthWest().lat,
        swlng: bounds?.getSouthWest().lng,
      }

      updateRouterQuery(mapBounds)
      onViewportChange?.(mapBounds)
    }, 500),
    [updateRouterQuery, onViewportChange],
  )

  const mapViewport = useMemo(() => {
    if (query.nelat && query.nelng && query.swlng && query.swlat) {
      return {
        northeast: {
          lng: Number(query.nelng),
          lat: Number(query.nelat),
        },
        southwest: {
          lng: Number(query.swlng),
          lat: Number(query.swlat),
        },
      }
    }

    return combinedMapBounds || getBounds(place, viewport) || null
  }, [combinedMapBounds, place, query.nelat, query.nelng, query.swlat, query.swlng, viewport])

  return (
    <Box
      flexGrow={1}
      position={{ medium: isFullScreenMode ? 'relative' : 'sticky' }}
      top={isFullScreenMode ? null : offsetTop}
      height={{
        default: viewportHeight,
        medium: `calc(${viewportHeight} - ${offsetTop}px)`,
      }}
      css={`
        .mapboxgl-popup {
          width: 272px;
        }

        .mapboxgl-ctrl-attrib {
          display: none;

          ${({ theme }) => theme.media.medium`
            display: block;
          `}
        }

        .mapboxgl-ctrl-group {
          display: none;

          ${({ theme }) => theme.media.medium`
            display: block;
          `}
        }
      `}
      ref={handleParentRef}
    >
      <StyledViewFullMapToggle
        isFullScreenMode={isFullScreenMode}
        onClick={() => {
          updateRouterQuery({ view: isFullScreenMode ? undefined : 'map' })
        }}
      >
        <IconResize size='26px' />
        <span>{isFullScreenMode ? t('Search::View less map') : t('Search::View full map')}</span>
      </StyledViewFullMapToggle>

      <Map
        mapViewport={mapViewport}
        zoom={mapViewport ? 15 : 0}
        onViewportChange={handleViewportChange}
        width={mapSize.width}
        height={mapSize.height}
        padding={hasMapQs ? undefined : mapPaddings}
        mapStyle={`${urlFactory.cfw.proxy.stadiamaps()}/styles/outdoors.json`}
      >
        <MapEvents
          highlightedEventsIds={highlightedEventsIdsList}
          events={events}
          shouldDisplayEventCard={shouldDisplayEventCard}
          onMarkerClick={onMarkerClick}
          placeDetails={placeDetails}
        />
      </Map>
    </Box>
  )
}

SearchMapView.propTypes = {
  onMarkerClick: PropTypes.func,
  shouldDisplayEventCard: PropTypes.bool,
  onViewportChange: PropTypes.func,
  paddings: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  highlightedEventsIds: PropTypes.oneOfType([
    PropTypes.immutable.list,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
}

SearchMapView.defaultProps = {
  onMarkerClick: undefined,
  onViewportChange: undefined,
  shouldDisplayEventCard: true,
  highlightedEventsIds: undefined,
  paddings: undefined,
}
