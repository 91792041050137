import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { NotFound } from 'components/shared/NotFound'
import { Box } from '@vizeat/components/es6/components/Box'
import { CobrandedBanner } from '../Banners/CobrandedBanner'
import { EventList } from 'components/shared/lists'
import { BasicSearchEventCard } from 'components/shared/cards'
import { Head } from '../Head'
import { NoResults } from './NoResults'
import { getSearchParamsFromRouterQuery, hasFiltersSelected } from 'helpers/search'
import { useSearchQueryWithFallback } from 'hooks/search/useSearchQuery'
import { Grid } from '@vizeat/components/es6/components/Grid'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { useTranslation } from 'next-i18next'
import { EventCardSkeleton } from '@vizeat/components/es6/components/EventCard'
import dynamic from 'next/dynamic'
import { SortBy } from './SortBy'
import { useClearFilters } from '../hooks/useClearFilters'
import { GTM_EVENT_TYPES } from 'gtm'
import { getLocalityAndCountry } from 'helpers/places'
import { Map } from 'immutable'

const SearchMapView = dynamic(() => import('../SearchMapView').then((mod) => mod.SearchMapView), { ssr: false })

function toggle(event) {
  return (eventId) => (eventId === event.id ? null : event.id)
}

const DEFAULT_DESTINATION_COVER = Map({ path: '/assets/images/about-us/eatwith-team-event.jpeg' })

export function SearchListView() {
  const { t } = useTranslation()
  const router = useRouter()
  const { query } = router
  const eventsRef = useRef({})
  const handleClearFilters = useClearFilters()

  const {
    selectData,
    searchQueryResult: { hasNextPage, fetchNextPage, isSuccess, isFetching, isError },
    hasResultsFromFallback,
  } = useSearchQueryWithFallback(getSearchParamsFromRouterQuery(query), {
    allowPublicBookings: true,
    keepPreviousData: true,
  })

  const { events, place, totalCount } = selectData((data) => data)
  const shouldDisplayNotFoundText = isSuccess && (events.size === 0 || hasResultsFromFallback)

  //! TODO will be retrieved from the search
  // const destinationCover = useSelector((state) => getSearchPlaceDetailsDestinationCover(state, router.query.q))
  const destinationTitle = useMemo(() => getLocalityAndCountry({ place }), [place])

  function handleEventRef(eventId) {
    return (node) => {
      eventsRef.current[eventId] = node
    }
  }

  const [selectedEventId, setSelectedEventId] = useState(null)
  const handleScrollToEventCard = useCallback((event) => {
    setSelectedEventId(toggle(event))
    eventsRef.current?.[event.id]?.scrollIntoView({ block: 'center' })
  }, [])

  const [hoveredEventId, setHoveredEventId] = useState(null)
  function handleHoveredEventIdToggle(event) {
    return () => {
      setHoveredEventId(toggle(event))
    }
  }

  const handleScrollToTop = useCallback(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isError) return <NotFound />

  return (
    <>
      {router.query.brand && router.query.brand === 'leroutard' && <CobrandedBanner />}
      <Head
        destinationCover={DEFAULT_DESTINATION_COVER} //! TODO will be retrieved from the search
        destinationTitle={destinationTitle}
        events={events}
        place={place}
      />
      <Grid
        margin='0 auto'
        position='relative'
        minHeight='100vh'
        templateColumns={{ tablet: '550px 1fr', large: '900px 1fr', extraLarge: '1000px 1fr' }}
      >
        <Box textAlign='center' pb='24px'>
          {shouldDisplayNotFoundText && (
            <NoResults place={place} resetFilters={hasFiltersSelected(query) ? handleClearFilters : undefined} />
          )}

          {events.size > 0 && (
            <>
              <Grid
                gap='8px'
                padding='0 24px'
                mb='24px'
                mt={hasResultsFromFallback ? '24px' : undefined}
                alignItems='baseline'
                gridTemplateColumns='4fr 1fr'
              >
                <Heading
                  forwardedAs='h1'
                  css={`
                    text-align: left;
                    margin: 0;
                  `}
                >
                  {hasResultsFromFallback
                    ? t('Search::Other experiences are located nearby')
                    : t('Search::{{count}} experience', { count: totalCount })}
                </Heading>
                <SortBy />
              </Grid>
              <EventList
                templateColumns={{ default: 'repeat(2, 1fr)', large: 'repeat(3, 1fr)' }}
                events={events}
                isLoading={isFetching}
                hasLoadMore={hasNextPage}
                loadingMore={isFetching}
                onLoadMore={fetchNextPage}
                totalCount={totalCount}
                place={place}
                renderSkeleton={(index) => (
                  <EventCardSkeleton width='100%' key={index} height={{ default: '280px', desktop: '340px' }} />
                )}
                render={(event, index) => (
                  <Box
                    key={event.id}
                    ref={handleEventRef(event.id)}
                    width='100%'
                    onMouseEnter={handleHoveredEventIdToggle(event)}
                    onMouseLeave={handleHoveredEventIdToggle(event)}
                  >
                    <BasicSearchEventCard
                      height={{ default: '280px', desktop: '340px' }}
                      event={event}
                      place={place}
                      isSelected={selectedEventId === event.id}
                      isHovered={hoveredEventId === event.id}
                      position={index}
                      gtmType={GTM_EVENT_TYPES.SEARCH}
                    />
                  </Box>
                )}
              />
            </>
          )}
        </Box>

        <SearchMapView
          onViewportChange={handleScrollToTop}
          shouldDisplayEventCard={false}
          onMarkerClick={handleScrollToEventCard}
          highlightedEventsIds={hoveredEventId}
        />
      </Grid>
    </>
  )
}
