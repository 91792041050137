import React, { memo, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useFilteredData } from '../hooks/useFilteredData'
import { useSearchQuery } from 'hooks/search/useSearchQuery'
import { getSearchFiltersFromRouterQuery, getSearchParamsFromRouterQuery } from 'helpers/search'
import { useRouter } from 'next/router'
import { Badge } from '@vizeat/components/es6/components/Badge'
import { Box } from '@vizeat/components/es6/components/Box'
import { ScrollableSlider } from 'components/shared/ScrollableSlider'
import { useUpdateRouterQuery } from 'hooks/router'
import PropTypes from 'helpers/proptypes'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { gtmFilterSelected } from 'gtm'
import { Tooltip } from '@vizeat/components/es6/components/Tooltip'
import { Map } from 'immutable'
import { IconCross } from '@vizeat/components/es6/assets/icons'
import { useTranslatedMealtypes } from 'hooks/events/useTranslatedFilters'

function StyledButton({ children, onClick, isSelected, ...styledProps }) {
  return (
    <Badge
      forwardedAs='button'
      onClick={onClick}
      borderRadius='lg'
      bg={isSelected ? 'eatwithOrange' : 'white'}
      color={isSelected ? 'white' : 'darkGray'}
      border='sm'
      borderColor={isSelected ? 'white' : 'lightGray'}
      p='8px'
      minWidth='auto'
      css={`
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background-color: ${({ theme }) => theme.colors.eatwithOrange};
          color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.white};
        }
      `}
      {...styledProps}
    >
      {children}
    </Badge>
  )
}

StyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
}

StyledButton.defaultProps = {
  isSelected: undefined,
}

// This component is used out of the search filters modal
// It interacts directly with the router, the implementation is different from the other components
export const MealType = memo(function MealType() {
  const { t } = useTranslation()
  const { query } = useRouter()
  const updateRouterQuery = useUpdateRouterQuery()
  const mealtypes = useTranslatedMealtypes()

  // use query.mealtypes instead of the whole query object
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedMealTypes = useMemo(() => getSearchFiltersFromRouterQuery(query, 'mealtypes') ?? [], [query.mealtypes])

  const searchParams = useMemo(() => {
    const { mealtypes, ...params } = getSearchParamsFromRouterQuery(query)
    return params
  }, [query])

  const { selectData } = useSearchQuery(searchParams, {
    allowPublicBookings: true,
    keepPreviousData: true,
  })

  const filteredMealtypes = useFilteredData({
    data: mealtypes,
    facets: selectData(({ facets }) => facets.get('type')) || Map({}),
  })

  function handleChange(mealType) {
    const mealTypesSet = new Set(selectedMealTypes)
    if (mealTypesSet.has(mealType)) mealTypesSet.delete(mealType)
    else mealTypesSet.add(mealType)

    const nextMealTypes = [...mealTypesSet]

    if (nextMealTypes.length > 0) {
      gtmFilterSelected('mealtypes', nextMealTypes.join(','))
    }

    updateRouterQuery({
      ...query,
      mealtypes: nextMealTypes.length > 0 ? nextMealTypes : undefined,
    })
  }

  function handleClear() {
    updateRouterQuery({
      ...query,
      mealtypes: undefined,
    })
  }

  if (filteredMealtypes.length === 0) return null

  return (
    <Flex m='12px auto 0' alignItems='center' justifyContent='center' maxWidth='100%'>
      {selectedMealTypes.length > 0 && (
        <Tooltip
          position='top'
          width={null}
          alignLeft
          renderContent={() => t('Forms::Reset')}
          parentStyles={`
            margin-right: 8px
          `}
        >
          <StyledButton
            arial-label={t('Forms::Reset')}
            title={t('Forms::Reset')}
            onClick={handleClear}
            width='42px'
            height='42px'
            justifyContent='center'
          >
            <IconCross size='20px' />
          </StyledButton>
        </Tooltip>
      )}

      <Box overflow='hidden'>
        <ScrollableSlider gap='8px'>
          {filteredMealtypes.map(({ title, localizedTitle, count, picto: Picto }) => (
            <StyledButton
              isSelected={selectedMealTypes.includes(title)}
              key={`meal-type-${title}`}
              onClick={() => {
                handleChange(title)
              }}
            >
              <Picto size='24px' mr='8px' />
              {localizedTitle} ({count})
            </StyledButton>
          ))}
        </ScrollableSlider>
      </Box>
    </Flex>
  )
})
