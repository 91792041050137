import React from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useLocale } from 'hooks/locales/useLocale'
// Components
import NextHead from 'next/head'
// helpers
import { urlFactory } from 'helpers/url'
import { mainImagesMeta, scaleCropFile } from 'helpers/ImageManager'
import { getCountry, getLocalityAndCountry, getLocality } from 'helpers/places'
import { getEventTitle } from 'helpers/legacy'

Head.propTypes = {
  events: PropTypes.immutable.list.isRequired,
  place: PropTypes.shape({}).isRequired,
  destinationCover: PropTypes.shape({}).isRequired,
  destinationTitle: PropTypes.string.isRequired,
}

export function Head({ events, place, destinationCover, destinationTitle }) {
  const { query, route } = useRouter()
  const { locale } = useLocale()
  const { t } = useTranslation()

  const { privatizable, mealtype, foodtypes, playlist } = query

  const title = t(
    'Meta::Title::Search::Top 20 immersive food events in {{locality}} : cooking classes, food tours, dinners, brunch, private parties...',
    { locality: destinationTitle },
  )

  const baseURI = urlFactory.web.canonical(locale)
  const searchQueryParam = query.q ? `?q=${query.q}` : ''
  const canonicalLinkHref = `${baseURI}${route}${searchQueryParam}`

  return (
    <NextHead>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta
        name='description'
        property='og:description'
        content={t(
          'Meta::Description::Search::Be part of immersive food events in {{locality}}. Unique meal sharing experiences with local hosts in more than 110 countries. Taste the city with locals',
          { locality: destinationTitle },
        )}
      />

      <link rel='canonical' href={canonicalLinkHref} key='canonical' />

      <meta
        property='og:url'
        content={urlFactory.web.search({
          q: getLocalityAndCountry({ place }),
          privatizable,
          mealtype,
          foodtypes,
          playlist,
        })}
      />

      {mainImagesMeta(destinationCover, '1600x500').map((props, key) => (
        <meta key={key} {...props} />
      ))}

      <script
        type='application/ld+json'
        id='jsonld-search-breadcrumb'
        key='jsonld-search-breadcrumb'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': urlFactory.web.search({ q: getCountry(place) }, locale),
                  url: urlFactory.web.search({ q: getCountry(place) }, locale),
                  name: getCountry(place),
                },
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': urlFactory.web.search({ q: getLocalityAndCountry({ place }) }, locale),
                  url: urlFactory.web.search({ q: getLocalityAndCountry({ place }) }, locale),
                  name: getLocality(place),
                },
              },
            ],
          }),
        }}
      />

      <script
        type='application/ld+json'
        id='jsonld-search'
        key='jsonld-search'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            itemListElement: events
              .filter((event) => !!event.id)
              .map((event, k) => ({
                '@type': 'ListItem',
                position: k + 1,
                item: {
                  '@id': urlFactory.web.events.get(event.id, locale),
                  url: urlFactory.web.events.get(event.id, locale),
                  name: getEventTitle(locale, event),
                  image: scaleCropFile({ fileId: event.cover_id, size: '1200x1200' }),
                },
              })),
          }),
        }}
      />
    </NextHead>
  )
}
