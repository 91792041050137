import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getSearchParamsFromRouterQuery } from 'helpers/search'
import { useSearchQueryWithFallback } from 'hooks/search/useSearchQuery'
import { useRouter } from 'next/router'
import { CarouselRtlSensitive } from 'components/shared/carousels'
import { EventCardSkeleton } from '@vizeat/components/es6/components/EventCard'
import { BasicSearchEventCard } from 'components/shared/cards'
import { InfiniteScroll } from '@vizeat/components/es6/components/InfiniteScroll'
import { usePrevious } from 'hooks'
import { StyledModal } from '@vizeat/components/es6/components/Modal'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { useTranslation } from 'next-i18next'
import { Box } from '@vizeat/components/es6/components/Box'
import styled, { css } from 'styled-components'
import { EventList } from 'components/shared/lists'
import dynamic from 'next/dynamic'
import { use100dvh } from '@vizeat/components/es6/hooks'
import { SPACE, MOBILE_SPACE } from '../SearchMapView'
import { SortBy } from './SortBy'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { useUpdateRouterQuery } from 'hooks/router'
import { GTM_EVENT_TYPES } from 'gtm'

const SearchMapView = dynamic(() => import('../SearchMapView').then((mod) => mod.SearchMapView), { ssr: false })
function isTouchEventSupported() {
  return typeof window !== 'undefined' && 'ontouchend' in window
}

const modalStyles = css`
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  z-index: 4;
  bottom: ${({ isVisible, viewportHeight }) =>
    isVisible ? '0' : `calc(-${viewportHeight} + ${SPACE + MOBILE_SPACE}px)`};
  height: ${({ viewportHeight }) => `calc(${viewportHeight} - ${SPACE}px)`};
  border: ${({ theme }) => `${theme.borders.sm} ${theme.colors.lightGray}`};
`

const StyledModalHeader = styled(Flex)`
  cursor: pointer;
  padding: 18px 18px 8px;
  background: ${({ theme }) => theme.colors.white};
  user-select: none;
  touch-action: none;
  flex-shrink: 0;
  flex-direction: column;

  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 6px;
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.radii.full};
  }
`

export function SearchMobileListView() {
  const { query } = useRouter()
  const { t } = useTranslation()
  const viewportHeight = use100dvh()
  const updateRouterQuery = useUpdateRouterQuery()

  const {
    selectData,
    searchQueryResult: { hasNextPage, fetchNextPage, isFetching },
    hasResultsFromFallback,
    placeDetails,
  } = useSearchQueryWithFallback(getSearchParamsFromRouterQuery(query), {
    allowPublicBookings: true,
    keepPreviousData: true,
  })

  const { events, totalCount } = selectData((data) => data)

  const prevEventsSize = usePrevious(events.size || 0)
  const skeletons = useMemo(() => Array.from({ length: prevEventsSize }, (_, index) => index), [prevEventsSize])

  const [visibleCarouselSlidesIndexes, setVisiblesCarouselSlidesIndexes] = useState([])
  const handleCarouselSlidesChange = useCallback(({ visibleSlides }) => {
    setVisiblesCarouselSlidesIndexes(visibleSlides)
  }, [])

  const visibleEventsIdsInCarousel = useMemo(() => {
    if (visibleCarouselSlidesIndexes.length === 0) return events
    return events.filter((_event, index) => visibleCarouselSlidesIndexes.includes(index)).map((event) => event.id)
  }, [events, visibleCarouselSlidesIndexes])

  const [selectedEvent, setSelectedEvent] = useState(null)
  const selectedEventIndex = useMemo(() => {
    if (!selectedEvent) return undefined
    return events.findIndex(({ id }) => id === selectedEvent.id)
  }, [events, selectedEvent])

  const scrollableEventsListRef = useRef(null)
  const handleModalScrollToTop = useCallback(() => {
    scrollableEventsListRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const isVisible = query.events_modal === 'true'
  const handleModalToggle = useCallback(() => {
    if (totalCount === 0) return
    updateRouterQuery({
      events_modal: isVisible ? undefined : 'true',
    })
  }, [isVisible, totalCount, updateRouterQuery])

  useEffect(() => {
    window.scrollTo(0, -1) // negative coord-y as a workaround for Safari iOS
  }, [])

  return (
    <>
      <SearchMapView
        shouldDisplayEventCard={false}
        onMarkerClick={setSelectedEvent}
        highlightedEventsIds={visibleEventsIdsInCarousel}
        paddings={{ bottom: hasResultsFromFallback ? 20 : 370 }}
      />
      {!hasResultsFromFallback && (
        <CarouselRtlSensitive
          indexToScroll={selectedEventIndex}
          onSlideChange={handleCarouselSlidesChange}
          cellSpacing={8}
          wrapperStyles={`
          z-index: 3;
          position: absolute;
          left: 8px;
          right: 8px;
          bottom: 100px;
      `}
          slideWidth='175px'
        >
          {isFetching
            ? skeletons.map((index) => <EventCardSkeleton key={index} variant='small' />)
            : events.map((event, index) => {
                return (
                  <BasicSearchEventCard
                    variant='small'
                    key={event.id}
                    event={event}
                    place={event.place}
                    showCarousel={false}
                    isSelected={selectedEventIndex === index}
                    gtmType={GTM_EVENT_TYPES.SEARCH}
                  />
                )
              })}

          {hasNextPage && (
            <InfiniteScroll
              canLoadMore
              onLoadMore={fetchNextPage}
              placeholderStyles='padding: 0; width: 100%; height: 100%;'
              Spinner={<EventCardSkeleton variant='small' />}
            />
          )}
        </CarouselRtlSensitive>
      )}

      <StyledModal
        isVisible={isVisible}
        variant='bottomToTop'
        largeBreakpoint='medium'
        viewportHeight={viewportHeight}
        css={modalStyles}
      >
        <StyledModalHeader
          onTouchStart={isTouchEventSupported() ? handleModalToggle : undefined}
          onClick={isTouchEventSupported() ? undefined : handleModalToggle}
        >
          <Flex padding='14px 0' justifyContent={isVisible ? null : 'center'}>
            <Heading
              forwardedAs='h1'
              css={css`
                text-align: ${isVisible ? null : 'center'};
                margin: 0;
              `}
            >
              {hasResultsFromFallback && placeDetails
                ? t('Search::{{count}} experience near {{locality}}', {
                    count: totalCount,
                    locality: placeDetails.locality,
                  })
                : t('Search::{{count}} experience', { count: totalCount })}
            </Heading>
            {isVisible && <SortBy onClick={handleModalScrollToTop} />}
          </Flex>
        </StyledModalHeader>

        <Box flexGrow={1} overflow='auto' p='0 18px 18px' ref={scrollableEventsListRef}>
          <EventList
            infiniteScroll
            templateColumns={{
              default: 'repeat(1, 1fr)',
              tablet: 'repeat(3, 1fr)',
              phablet: 'repeat(2, 1fr)',
            }}
            events={events}
            isLoading={isFetching}
            hasLoadMore={hasNextPage}
            loadingMore={isFetching}
            onLoadMore={fetchNextPage}
            totalCount={totalCount}
            renderSkeleton={(index) => (
              <EventCardSkeleton width='100%' key={index} height={{ default: '280px', desktop: '340px' }} />
            )}
            render={(event, index) => (
              <Box key={event.id} width='100%'>
                <BasicSearchEventCard
                  position={index}
                  height={{ default: '280px', desktop: '340px' }}
                  event={event}
                  gtmType={GTM_EVENT_TYPES.SEARCH}
                />
              </Box>
            )}
          />
        </Box>
      </StyledModal>
    </>
  )
}
