import { useUpdateRouterQuery } from 'hooks/router'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export function useClearFilters() {
  const updateRouterQuery = useUpdateRouterQuery()
  const { query } = useRouter()

  return useCallback(() => {
    updateRouterQuery(
      {
        q: query.q || undefined,
        pid: query.pid || undefined,
        datefrom: query.datefrom || undefined,
        dateto: query.dateto || undefined,
        nbguests: query.nbguests || undefined,
      },
      { shouldReset: true },
    )
  }, [query.datefrom, query.dateto, query.nbguests, query.pid, query.q, updateRouterQuery])
}
